import React from 'react';
import AngiKey from './AngiKey';

export default {
  title: 'Checkout/Shared/AngiKey',
  component: AngiKey,
};

const Template = (args) => (
  <AngiKey {...args} />
);

export const DefaultAngiKey = Template.bind({});
DefaultAngiKey.args = {};

export const LargeAngiKey = Template.bind({});
LargeAngiKey.args = {
  style: {
    height: '100px',
    width: '100px',
  },
};
