import React from 'react';
import { PlusMinus } from '.';

export default {
  title: 'Checkout/Shared/PlusMinus',
  component: PlusMinus,
  argTypes: {
    color: {
      options: ['action', 'disabled', 'error', 'inherit', 'primary', 'secondary'],
      control: { type: 'select' },
    },
  },
};

const Template = (args) => (
  <PlusMinus {...args} />
);

export const Plus = Template.bind({});
Plus.args = {
  minus: false,
  color: 'primary',
  style: {},
};

export const Minus = Template.bind({});
Minus.args = {
  minus: true,
  color: 'primary',
  style: {},
};
