import React from 'react';
import Caret from './Caret';

export default {
  title: 'Checkout/Shared/Caret',
  component: Caret,
  argTypes: {
    color: {
      options: ['action', 'disabled', 'error', 'inherit', 'primary', 'secondary'],
      control: { type: 'select' },
    },
    type: {
      options: [undefined, 'triangle', 'chevron'],
    },
  },
};

const Template = (args) => (
  <Caret {...args} />
);

export const Up = Template.bind({});
Up.args = {
  up: true,
  color: 'action',
  style: {},
};

export const Down = Template.bind({});
Down.args = {
  up: false,
  color: 'action',
  style: {},
};
